.priceByBedWrapper {
  --horizontal-scroll-container-padding: 0;
  padding: var(--space-none) var(--space-3-5x);
  width: 100%;
  display: flex;
  font-size: var(--font-size-body);
  margin-top: var(--space-2-5x);
  container-type: inline-size;
}

@media (--screen-xl) {
  .moveInfoToBottom.priceByBedWrapper {
    margin-bottom: unset;
    margin-top: auto;
  }
}

.priceByBedCount {
  display: flex;
  flex-direction: column;
  margin-right: var(--space-5x);
  line-height: var(--leading-tight);
  gap: var(--space-1-5x);

  &:not(:first-child) {
    padding-left: var(--space-5x);
    border-left: 1px solid var(--color-gray-200);
  }
  &:last-child {
    margin-right: 0;
  }
}

/*
  https://redfin.atlassian.net/browse/WEB-17296:
  Remove container-type from .priceByBedWrapper and
  remove this container query once the saved page cards are responsive
*/
@container (max-width: 330px) {
  .priceByBedCount {
    margin-right: var(--space-4x);
  }
}

.priceText {
  color: var(--color-black);
  font-weight: var(--font-weight-semibold);
}

.sqFtText {
  white-space: nowrap;
}
